import { useEffect, useRef } from "react";

const FYE = ({status, setStatus, loading, setLoading, params}) => {
  let form = useRef();

  useEffect(()=>{
    setStatus('Submitting ATC Request...');
    setTimeout(()=>{
      setLoading(false);
      form.current.submit();
    }, 500);
  }, [])

  return (
    <>
      <form ref={form} action="https://www.fye.com/on/demandware.store/Sites-FYE-Site/default/Cart-AddProduct" method="post" novalidate="novalidate">
        <input type="hidden" name="uuid" value=""/>
        <input type="hidden" name="cartAction" value="update" />
        <input type="hidden" name="pid" value={params.get('pid')}/>
      </form>
    </>
  );
}

export default FYE;