import { Box, Spinner, Text } from "@chakra-ui/react";

const StatusBlock = ({status, loading, tipText}) => {
  return (
    <Box textAlign={'center'} my={{base:2, md:4}}>
      <Text fontSize={{base:'md', md:'lg', lg:'xl', xl:'2xl'}}>
        {status}
      </Text>
      <Text color='gray.500' fontSize={{base:'xs', md:'sm', lg:'md', xl:'lg'}} my={{base:2}} as='i'>
        {tipText || ''}
      </Text>
      <Box h='55px' my={{base:4, md:5}}>
          <Spinner
            thickness={{base:'3px', lg:'4px'}}
            speed='1s'
            emptyColor='gray.700'
            color='#EE4100'
            size={{base:'lg', lg: 'xl'}}
            transition={loading !== true ? 'all 1s ease-in' : 'all 0.3s ease-in'}
            opacity={loading !== true ? 0 : 1}
          />
      </Box>
    </Box>
  );
}

export default StatusBlock;