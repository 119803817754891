import { useEffect, useRef } from "react";

const BoxLunch = ({status, setStatus, loading, setLoading, params}) => {
  let form = useRef();
  let popupWindow = useRef(window);

  useEffect(()=>{
    let popup = params.get('popup');
    let pid = params.get('pid');
    let wishlist = params.get('wishlist');

    if (popup === undefined || popup === null){
      if (wishlist !== null){
        popupWindow.current = window.open(`/?site=boxlunch&popup=true&pid=${pid}&wishlist=true`, "", "width=400,height=600,left=200,top=200");
        setStatus(`Adding PID to wishlist...`);
      } else {
        popupWindow.current = window.open(`/?site=boxlunch&popup=true&pid=${pid}`, "", "width=400,height=600,left=200,top=200");
        setStatus('Submitting ATC request in popup window...');
      }
      setTimeout(()=>{
        if (popupWindow.current === null){
          setLoading(false);
          setStatus(`Allow popups and refresh the window...`);
        } else {
          setStatus(`Closing popup and redirecting to BoxLunch ${wishlist !== null ? 'wishlist' : 'cart'}...`);
          setTimeout(()=>{
            setLoading(false);
            setTimeout(()=>{
              // popupWindow.current.close();
              if (wishlist !== null) window.location.href = 'https://www.boxlunch.com/wishlist';
              else window.location.href = 'https://www.boxlunch.com/cart';
            }, 250);
          }, 625);
        }
      }, 625);
    } else if (popup !== undefined && popup !== null && popup === 'true'){
      handleSubmit();
    }
  }, []);

  const handleSubmit = () => {
    setStatus('Sending ATC Request...');
    form.current.submit();
  }

  return (
    <>
      {
        params.get('wishlist') !== null ? (
          <form ref={form} action={`https://www.boxlunch.com/on/demandware.store/Sites-boxlunch-Site/default/Wishlist-AddProduct?pid=${params.get('pid')}`} method="post" novalidate="novalidate">
            <input type="hidden" name="pid" value={params.get('pid')}/>
            <input type="hidden" name="optionId" value=""/>
            <input type="hidden" name="optionVal" value="" />
          </form>
        ) : (
          <form ref={form} action="https://www.boxlunch.com/on/demandware.store/Sites-boxlunch-Site/default/Cart-AddProduct" method="post" novalidate="novalidate">
            <input type="hidden" name="pid" value={params.get('pid')}/>
            <input type="hidden" name="quantity" value={params.get('qty') || 1}/>
            <input type="hidden" name="options" value="[]" />
            <input type="hidden" name="shippingMethod" value="shipToHome" />
          </form>
        )
      }
    </>
  );
}

export default BoxLunch;