import { useEffect, useState } from "react";
import { ChakraProvider, Box, Container, Heading } from '@chakra-ui/react';
import { useSearchParams } from "react-router-dom";
import { Analytics } from '@vercel/analytics/react';
import theme from "./theme.js"

import StatusBlock from './components/StatusBlock.js'
import FYE from './pages/FYE.js';
import Disney from './pages/Disney.js';
import BoxLunch from './pages/BoxLunch.js';
import HotTopic from './pages/HotTopic.js';
// import LoaderBar from "./components/LoaderBar/LoaderBar.js";
import FunkoShop from "./pages/FunkoShop.js";

const App = () => {
  let [status, setStatus] = useState('');
  let [tip, setTip] = useState('');
  let [loading, setLoading] = useState(null);
  let [params] = useSearchParams();

  let site = params.get('site');
  let pid = params.get('pid');
  let wishlist = params.get('wishlist');

  useEffect(()=>{
    if (site === null || site === undefined || site === '' || !/boxlunch|disney|fye|hottopic|fs/i.test(site)){
      setStatus('Invalid site. Check your link and try again...');
      setLoading(null);
    } else if (pid === undefined || pid === null || pid === ''){
      setStatus(`Missing PID! Check your link and try again...`);
      setLoading(null);
    } else setLoading(true);
  }, [site, pid]);

  useEffect(()=>{
    if (wishlist !== null) setTip(`You must be logged into your account in order to add to wishlist.`)
  }, []);

  let atcProps = {
    status, setStatus, loading, setLoading, params
  }

  return (
    <ChakraProvider theme={theme}>
      <Analytics />
      <Container minW={'200px'} maxW={{base:'3xl'}} marginTop={5}>
        <Box textAlign="center">
          <Heading fontFamily={"'Roboto Slab', serif;"} size={{base:'2xl', sm:'2xl', lg:'3xl', xl: '4xl'}}>
            AwwBots
          </Heading>
        </Box>
        <StatusBlock status={status} loading={loading} tipText={tip}/>
        {
          pid === undefined || pid === null || pid === '' ? null :
          /boxlunch/i.test(site) ? <BoxLunch {...atcProps}/> :
          /disney/i.test(site) ? <Disney {...atcProps} /> :
          /fye/i.test(site) ? <FYE {...atcProps}/> :
          /hottopic/i.test(site) ? <HotTopic {...atcProps}/> :
          // /fs/i.test(site) ? <FunkoShop {...atcProps}/> :
          null
        }
      </Container>
    </ChakraProvider>
  );
}

export default App;
