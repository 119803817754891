import { useEffect, useRef } from "react";

const Disney = ({status, setStatus, loading, setLoading, params}) => {
  let form = useRef();
  let popupWindow = useRef(window);

  useEffect(()=>{
    let popup = params.get('popup');
    let pid = params.get('pid');
    if (popup === undefined || popup === null){
      popupWindow.current = window.open(`/?site=disney&popup=true&pid=${pid}`, "", "width=400,height=600,left=200,top=200");
      setStatus('Submitting ATC Request in popup window...');
      setTimeout(()=>{
        if (popupWindow.current === null){
          setLoading(false);
          setStatus(`Allow popups and refresh the window...`);
        } else {
          setStatus('Closing popup and redirecting to Disney cart...');
          setTimeout(()=>{
            setLoading(false);
            setTimeout(()=>{
              popupWindow.current.close();
              window.location.href = 'https://www.shopdisney.com/my-bag';
            }, 250);
          }, 625);
        }
      }, 625);
    } else if (popup !== undefined && popup !== null && popup === 'true'){
      handleSubmit();
    }
  }, [])

  const handleSubmit = () => {
    setStatus('Sending ATC Request...');
    form.current.submit();
  }

  return (
    <>
      <form ref={form} action="https://www.shopdisney.com/on/demandware.store/Sites-shopDisney-Site/default/Cart-AddProduct" method="post" novalidate="novalidate">
        <input type="hidden" name="pid" value={params.get('pid')}/>
        <input type="hidden" name="quantity" value={params.get('qty') || 1}/>
        <input type="hidden" name="isPdp" value="true" />
      </form>
    </>
  );
}

export default Disney;
